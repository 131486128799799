<template>
  <!-- PTZ解决方案 -->
  <div class="solution">
    <div class="banner">
      <img
        :src="areaPTZ.solutionPcImg ? areaPTZ.solutionPcImg : ''"
        v-if="this.$store.state.pageset.pcshow"
      />
      <img :src="areaPTZ.solutionPhoneImg ? areaPTZ.solutionPhoneImg : ''" v-else />
      <div class="dec" v-animate="'queue-bottom'">
        <div class="dec-left">
          <p class="p1">{{ areaPTZ.solutionName }}</p>
        </div>
        <div class="dec-right">
          <p class="p2">
            {{ areaPTZ.solutionDescription }}
          </p>
        </div>
      </div>
    </div>
    <!-- 内容 -->
    <div class="solution-content">
      <!-- 区域 -->
      <p class="content-title"> Zoning Plan</p>
      <div class="content-area">
        <div
          v-for="(item, index) in areaPTZ.webControlSolutionAreaList"
          :key="index"
          class="content-area-item"
          @click="openDetail(item.areaId)"
        >
          <img
            :src="item.areaPcImg ? item.areaPcImg : ''"
            v-if="$store.state.pageset.pcshow"
          />
          <img :src="item.areaPhoneImg ? item.areaPhoneImg : ''" v-else />
          <div class="content-area-dec">
            <p class="p1" v-animate="'queue-bottom'">{{ item.areaName }}</p>
          </div>
        </div>
      </div>
      <!-- 痛点问题 -->
      <p class="content-title">Pain point issues</p>
      <div class="content-pain">
        <div
          v-for="(item, index) in areaPTZ.webControlSolutionPainList"
          :key="index"
          class="content-pain-item"
        >
          <div class="content-pain-item-con">
            <img
              :src="item.painPcImg ? item.painPcImg : ''"
              v-if="$store.state.pageset.pcshow"
            />
            <img :src="item.painPcImg ? item.painPhoneImg : ''" v-else />
            <div class="content-pain-dec" v-animate="'queue-bottom'">
              <p class="p1">{{ item.painName }}</p>
              <p class="p2">{{ item.painDescription }}</p>
            </div>
          </div>
        </div>
      </div>
      <!-- 解决方案 -->
      <p class="content-title">Solution</p>
      <div class="content-programme">
        <div
          v-for="(item, index) in areaPTZ.webControlSolutionCaseList"
          :key="index"
          class="content-programme-item"
        >
          <div class="programme-img">
            <img
              :src="item.solutionCasePcImg ? item.solutionCasePcImg : ''"
              v-if="$store.state.pageset.pcshow"
            />
            <img
              :src="item.solutionCasePhoneImg ? item.solutionCasePhoneImg : ''"
              v-else
            />
          </div>
          <div class="content-programme-dec">
            <p class="p1" v-animate="'queue-bottom'">
              {{ item.solutionCaseName }}
            </p>
          </div>
        </div>
      </div>

      <!-- 管控平台 -->
      <p class="content-title">Management and control of the platform</p>
      <div class="control-platform">
        <div class="mySwiper img-swiper">
          <div class="swiper-wrapper screen-swiper">
            <div class="swiper-slide" v-for="(item, index) in controlList" :key="index">
              <!-- 滑动切换内容 -->
              <img :src="item.pcImg" v-if="$store.state.pageset.pcshow" />
              <img :src="item.phoneImg" v-else />
              <div class="swiper-pagination"></div>
              <div class="mobile-page" v-if="!$store.state.pageset.pcshow">
                <p class="p-name">{{ item.descOne }}</p>
                <p class="p-dec">{{ item.descTwo }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="order" v-if="$store.state.pageset.pcshow">
        <el-button size="small" class="btn-div-experience" plain>更多解决方案</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import ptzSolution from "./ptzSolution_en.js";
export default ptzSolution;
</script>
<style lang="scss" scoped>
@import "~@/assets/style/common.scss";
@import "../../../commonStyle/solutionArea.scss";
@import "../../../commonStyle/solutionDetail.scss";
@import "../ptzSolution.scss";
@import "./ptzSolution_en.scss";
</style>
