import Swiper from "swiper";
export default {
    data() {
        return {
            areaObj: {},
            areaPTZ: {
                solutionId: 999,
                solutionName: "SAAS system service solution",
                solutionDescription: "Conduct 24-hour continuous testing at fixed testing points in production areas. It has the characteristics of easy installation and wide application range. Through the combination of fixed pan-tilt and cloud management & control platform, the safety, timeliness and effectiveness of the inspection work are improved.",
                solutionPcImg: require("../images/pc/PTZ.jpg"),
                solutionPhoneImg: require("../images/mobile/PTZ.jpg"),
                webControlSolutionAreaList: [
                    {
                        areaName: "Reaction Unit",
                        areaPcImg: require("../images/pc/p1.jpg"),
                        areaPhoneImg:  require("../images/mobile/p1.jpg"),
                        sort: "1",
                    },
                    {
                        areaName: "Gateway",
                        areaPcImg: require("../images/pc/p2.jpg"),
                        areaPhoneImg:  require("../images/mobile/p2.jpg"),
                        sort: "1",
                    },
                    {
                        areaName: "Feed pump",
                        areaPcImg: require("../images/pc/p3.jpg"),
                        areaPhoneImg:  require("../images/mobile/p3.jpg"),
                        sort: "1",
                    },
                    {
                        areaName: "Work Zone",
                        areaPcImg: require("../images/pc/p4.jpg"),
                        areaPhoneImg:  require("../images/mobile/p4.jpg"),
                        sort: "1",
                    },
                    {
                        areaName: "Level gauge",
                        areaPcImg: require("../images/pc/p5.jpg"),
                        areaPhoneImg:  require("../images/mobile/p5.jpg"),
                        sort: "1",
                    },
                    {
                        areaName: "Production unit",
                        areaPcImg: require("../images/pc/p6.jpg"),
                        areaPhoneImg:  require("../images/mobile/p6.jpg"),
                        sort: "1",
                    },
                ],
                webControlSolutionPainList: [
                    {
                        painName: "Dense and narrow area",
                        painDescription: "Densely stacked of the equipment and space is relatively small，it is difficult for the inspection personnel and wheeled inspection robots to enter for inspection.",
                        painPhoneImg: require("../images/mobile/p7.png"),
                        painPcImg: require("../images/pc/p7.png"),
                    }, {
                        painName: "Meter identification & read",
                        painDescription: "Check and record the meter readings, check and identify the opening and closing status of the valves and determine whether there is any abnormality.",
                        painPhoneImg: require("../images/mobile/p8.png"),
                        painPcImg: require("../images/pc/p8.png"),
                    },
                    {
                        painName: "Detection position is relatively high",
                        painDescription: "The liquid level gauge is in a higher position, and it requires manual monitoring by climbing high.  Ground robot cannot inspect such location as there are inspection blind spots.",
                        painPhoneImg: require("../images/mobile/p9.png"),
                        painPcImg: require("../images/pc/p9.png"),
                    },
                    {
                        painName: "Leakage Detection",
                        painDescription: "Leakage detection in tank and pipeline areas, check whether there is any liquid leakage.",
                        painPhoneImg: require("../images/mobile/p10.png"),
                        painPcImg: require("../images/pc/p10.png"),
                    },
                    {
                        painName: "Personnel intrusion detection",
                        painDescription: "Resources of existing video surveillance can be used to implement personnel intrusion detection at the entrance to the factory area or work area. ",
                        painPhoneImg: require("../images/mobile/p11.png"),
                        painPcImg: require("../images/pc/p11.png"),
                    },
                    {
                        painName: "Loop playback of surveillance images",
                        painDescription: "As there are many resources need to be accessed, the platform needs to be equipped with monitoring loop playback function. Each device contains three sets of data，they are visible light, infrared light and gas sensors, and they will be loop displayed on the platform . ",
                        painPhoneImg: require("../images/mobile/p12.png"),
                        painPcImg: require("../images/pc/p12.png"),
                    },
                    {
                        painName: "Monitoring on the environmental gas",
                        painDescription: "Identify if there is any leakage of toxic and harmful gases.",
                        painPhoneImg: require("../images/mobile/p13.png"),
                        painPcImg: require("../images/pc/p13.png"),
                    },
                    {
                        painName: "Detection on the safety behaviour",
                        painDescription: "Use existing video surveillance resources to implement safe behavior detection in the work zone.",
                        painPhoneImg: require("../images/mobile/p14.png"),
                        painPcImg: require("../images/pc/p14.png"),
                    },
                ],//痛点问题
                webControlSolutionCaseList: [
                    {
                        solutionCaseName: "An explosion-proof fixed pan-tilt equipped with a gas detection module is used to cover inspection blind areas and realize environmental gas detection.",
                        solutionCaseDescription: null,
                        solutionCasePcImg: require("../images/pc/p15.jpg"),
                        solutionCasePhoneImg: require("../images/mobile/p15.jpg"),
                    },
                    {
                        solutionCaseName: "Access to the video surveillance resources of the plant and apply algorithms to implement personnel intrusion detection",
                        solutionCaseDescription: null,
                        solutionCasePcImg: require("../images/pc/p16.jpg"),
                        solutionCasePhoneImg: require("../images/mobile/p16.jpg"),
                    },
                    {
                        solutionCaseName: "Temperature of the equipment can be detected by infrared thermal imaging technology , while over-temperature and severe temperature rise can also be monitored.",
                        solutionCaseDescription: null,
                        solutionCasePcImg: require("../images/pc/p17.jpg"),
                        solutionCasePhoneImg: require("../images/mobile/p17.jpg"),
                    },
                    {
                        solutionCaseName: "Apply artificial intelligence algorithms to detect personnel safety behavior and monitor whether they are wearing labor protection equipment correctly, and detect whether there are illegal activities such as smoking and making phone calls.",
                        solutionCaseDescription: null,
                        solutionCasePcImg: require("../images/pc/p18.jpg"),
                        solutionCasePhoneImg: require("../images/mobile/p18.jpg"),
                    },
                    {
                        solutionCaseName: "Apply image identification function to detect meter reading, leakage, liquid level identification, etc.",
                        solutionCaseDescription: null,
                        solutionCasePcImg: require("../images/pc/p19.jpg"),
                        solutionCasePhoneImg: require("../images/mobile/p19.jpg"),
                    },
                    {
                        solutionCaseName: "All connected devices are displayed on the robot management platform interface, and monitoring resources can be loop displayed.",
                        solutionCaseDescription: null,
                        solutionCasePcImg: require("../images/pc/p20.jpg"),
                        solutionCasePhoneImg: require("../images/mobile/p20.jpg"),
                    },
                ],//解决方案
            },
            controlList: [{
                pcImg: require("../images/pc/p21.jpg"),
                phoneImg: require("../images/mobile/p21.jpg"),
                descOne: 'Data collection and intelligent analysis',
                descTwo: "The robot management system can manage and control of the robot and pan tilt, robot inspection simultaneously.The system has completely independent intellectual property rights, and it can be connected to owner's existing DCS and other systems. The video surveillance system can be connected and integrated, and customized function development can be realized to provide customers with one -stop solutions."
            },
            {
                pcImg: require("../images/pc/p22.jpg"),
                phoneImg: require("../images/mobile/p22.jpg"),
                descOne: 'Proprietary technology and customized service',
                descTwo: 'The robot management system is divided into data collection layer, data storage and analysis layers, data application layer and others layers. It provides a number of functions such as robot summary, task overview, historical data viewing, early warning review, results analysis, big data analysis, inspection strategy, etc., from the smallest inspection item to annual data analysis，it provides customers with comprehensive inspection supervision.  Starting from the basics and all information will be presented by data.'
            },
            {
                pcImg: require("../images/pc/p23.jpg"),
                phoneImg:require("../images/mobile/p23.jpg"),
                descOne: 'Video monitoring and early warning processing',
                descTwo: 'Explosion-Proof pan-tilt and existing video can provide a video surveillance viewing interface, support viewing all equipment and online equipment options, support early warning alarm, support the processing of early warning, and provide customers with one -stop processing capabilities.'
            },],
            aboutswiper: null,
        }
    },
    mounted() {
        this.$store.dispatch("pageset/getbannerIndex", 0);
        document.documentElement.style.setProperty('--var-primary-color', '#fff');
        this.aboutSwiper();
    },
    // 销毁滚轮事件
    destroyed() {
        // 销毁swiper
        this.aboutswiper.destroy();
    },
    methods: {
        openDetail(areaId) {
            this.$router.push({
                path: "/areaDetail",
                query: {
                    areaId: areaId,
                },
            })
        },
        // 初始化轮播图
        aboutSwiper() {
            let that = this;
            this.aboutswiper = new Swiper('.mySwiper', {
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    renderBullet: function (index, className) {
                        console.log(index,className);
                        if(that.$store.state.pageset.pcshow){
                            return  '<div class="p-bullet ' + className + '"> '+
                            '<p class="p-name">' + (that.controlList[index].descOne) +
                             '</p>' +
                             '<p class="bullet-line"></p>'+
                             '<p class="p-dec">' + (that.controlList[index].descTwo) +
                             '</p>'
                             +'</div>';
                        }else{
                            return  '<span class="p-bullet ' + className + '"> '+
                           '</span>';
                        }
                    },
                },
                autoplay: true,
                speed: 2000,
                loop: true,
                effect: 'fade',//切换效果
                fadeEffect: {
                    crossFade: true  //开启淡出。过渡时，原slide透明度从1->0（淡出），过渡中的slide透明度从0->1（淡入），其他slide透明度0。
                    //false  关闭淡出。过渡时，原slide透明度为1（不淡出），过渡中的slide透明度从0->1（淡入），其他slide透明度0。
                },
            });
        },
    },
}